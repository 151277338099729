export class JwtTokenService {
  static decodeJwtToken(token) {
    const base64Payload = token.split('.')[1];
    const payload = Buffer.from(base64Payload, 'base64');
    console.log(JSON.parse(payload.toString()));

    return JSON.parse(payload.toString());
  }

  static getUserIdFromJwtToken(token) {
    const decodedToken = this.decodeJwtToken(token);

    return decodedToken.sub;
  }
}

const jwtTokenService = JwtTokenService;
export default jwtTokenService;
